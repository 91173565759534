
import AppSubmenu from "./WorkspaceSubmenu.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "AppMenu",
  components: { AppSubmenu },
  emits: ["menu-click", "menuitem-click", "root-menuitem-click"],
  props: {
    model: Array,
    layoutMode: String,
    active: Boolean,
    mobileMenuActive: Boolean,
    activityLogo: String,
  },
  setup(_, { emit }) {
    function onMenuClick(event: any) {
      emit("menu-click", event);
    }
    function onMenuItemClick(event: any) {
      emit("menuitem-click", event);
    }
    function onRootMenuItemClick(event: any) {
      emit("root-menuitem-click", event);
    }
    return {
      onMenuClick,
      onMenuItemClick,
      onRootMenuItemClick,
      appName: process.env.VUE_APP_NAME,
    };
  },
});
