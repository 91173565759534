import {
  QueryGetSoftRemovedResponsibleArgs,
  Responsible,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useQuery, useResult } from "@vue/apollo-composable";
import { activeActivity } from "@/plugins/i18n";

type GetSoftRemoveResponsibleData = {
  getSoftRemovedResponsible: Responsible[];
};

const SOFT_REMOVE = gql`
  query GetSoftRemovedResponsible($activityId: Int!) {
    getSoftRemovedResponsible(activityId: $activityId) {
      id
      roleId
      deletedAt
      user {
        id
        name
        image
        email
      }
    }
  }
`;

export const useGetSoftRemovedResponsible = () => {
  const { result } = useQuery<
    GetSoftRemoveResponsibleData,
    QueryGetSoftRemovedResponsibleArgs
  >(SOFT_REMOVE, () => ({
    activityId: activeActivity.value.id,
  }));

  return {
    responsible: useResult<
      GetSoftRemoveResponsibleData,
      Responsible[],
      Responsible[]
    >(result, [], (res) => res.getSoftRemovedResponsible),
  };
};
