
import EventBus from "../../event-bus";
import PosTopBar from "./WorkspaceTopbar.vue";
import PosFooter from "./WorkspaceFooter.vue";
import PosConfig from "./LayoutConfig.vue";
import { defineComponent, ref } from "vue";
import { MenuSettings } from "@/layouts/workspace/menu-settings";

export default defineComponent({
  props: ["menu", "activity"],
  setup() {
    const settings = ref([2, 1, 0, 1, 1, 0]);
    const { menuTypes, colorSchemes, menuThemes } = MenuSettings;
    return {
      menuTypes,
      colorSchemes,
      menuThemes,
      menuActive: ref(false),
      settings,
      overlayMenuActive: ref(false),
      staticMenuDesktopInactive: ref(false),
      staticMenuMobileActive: ref(false),
      menuClick: ref(false),
      userMenuClick: ref(false),
      topbarUserMenuActive: ref(false),
      notificationMenuClick: ref(false),
      topbarNotificationMenuActive: ref(false),
      configActive: ref(false),
      configClick: ref(false),
    };
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        `layout-${this.menuTypes[this.settings[0]]}`,
        `layout-sidebar-${this.colorSchemes[this.settings[1]]}`,
        {
          "layout-overlay-active": this.overlayMenuActive,
          "layout-mobile-active": this.staticMenuMobileActive,
          "layout-static-inactive":
            this.staticMenuDesktopInactive && this.settings[0] === 0,
        },
        this.settings[1] === 1 ? this.menuThemes[this.settings[4]].class : "",
      ];
    },
  },
  components: {
    PosTopBar,
    PosFooter,
    PosConfig,
  },
  watch: {
    $route() {
      this.menuActive = false;
    },
  },
  methods: {
    onDocumentClick() {
      if (!this.userMenuClick) {
        this.topbarUserMenuActive = false;
      }

      if (!this.notificationMenuClick) {
        this.topbarNotificationMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isSlim() || this.isHorizontal()) {
          EventBus.emit("reset-active-route");
          this.menuActive = false;
        }

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
          this.hideOverlayMenu();
        }

        this.unblockBodyScroll();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      this.configClick = false;
      this.userMenuClick = false;
      this.notificationMenuClick = false;
      this.menuClick = false;
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onMenuButtonClick(event: any) {
      this.menuClick = true;
      this.topbarUserMenuActive = false;
      this.topbarNotificationMenuActive = false;

      if (this.isOverlay()) {
        this.overlayMenuActive = !this.overlayMenuActive;
      }

      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
        if (this.staticMenuMobileActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      }

      event.preventDefault();
    },
    onMenuItemClick(event: any) {
      if (!event.item.items) {
        EventBus.emit("reset-active-route");
        this.hideOverlayMenu();
      }
      if (!event.item.items && (this.isSlim() || this.isHorizontal())) {
        this.menuActive = false;
      }
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onTopbarUserMenuButtonClick(event: any) {
      this.userMenuClick = true;
      this.topbarUserMenuActive = !this.topbarUserMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },
    onTopbarNotificationMenuButtonClick(event: any) {
      this.notificationMenuClick = true;
      this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
    },
    onConfigClick() {
      this.configClick = true;
    },
    onConfigButtonClick() {
      this.configActive = !this.configActive;
      this.configClick = true;
    },
    hideOverlayMenu() {
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
      this.unblockBodyScroll();
    },
    blockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.add("blocked-scroll");
      } else {
        document.body.className += " blocked-scroll";
      }
    },
    unblockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.remove("blocked-scroll");
      } else {
        document.body.className = document.body.className.replace(
          new RegExp(
            "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
      }
    },
    isSlim() {
      return this.settings[0] === 2;
    },
    isHorizontal() {
      return this.settings[0] === 3;
    },
    isOverlay() {
      return this.settings[0] === 1;
    },
    isDesktop() {
      return window.innerWidth > 1091;
    },
  },
});
