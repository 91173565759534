
import { defineComponent, ref, watch } from "vue";
import { useWHoAmI } from "@/graphql/user/whoAmi";
import { useUpdateUser } from "@/graphql/user/update.user";
import { changeStyle } from "@/layouts/workspace/menu-settings";

export default defineComponent({
  name: "PosConfig",
  emits: ["config-button-click", "config-click", "update:settings"],
  props: [
    "configActive",
    "configClick",
    "menuThemes",
    "colorSchemes",
    "menuTypes",
    "settings",
  ],
  setup(props, { emit }) {
    const { Iam, IamLoading } = useWHoAmI();
    const { loading, updateUser } = useUpdateUser(false);
    const { changeStyleSheetUrl } = changeStyle();
    const menuColor = ref(props.menuThemes[props.settings[4]].name);
    function setSetting(index, value: number) {
      const settings = [...props.settings];
      settings[index] = value;
      emit("update:settings", settings);
      updateUser({ settings });
    }
    function changeMenuTheme(index: number, update = true) {
      const name = props.menuThemes[index].name;
      if (update) this.setSetting(4, index);
      const appLogoLink = document.getElementById("app-logo");
      const logoUrl = `/assets/layout/images/logo-${
        name === "white" ? "dark" : "white"
      }.svg`;
      const horizontalLogoLink = document.getElementById("logo-horizontal");

      if (appLogoLink) {
        appLogoLink.setAttribute("src", logoUrl);
      }
      if (horizontalLogoLink) {
        horizontalLogoLink.setAttribute("src", logoUrl);
      }
      menuColor.value = name;
    }
    function changeColorScheme(index: number, update = true) {
      const scheme = props.colorSchemes[index];
      if (update) setSetting(1, index);
      changeStyleSheetUrl("layout-css", "layout-" + scheme + ".css", 1);
      changeStyleSheetUrl("theme-css", "theme-" + scheme + ".css", 1);
    }
    watch(
      () => Iam.value,
      (user) => {
        if (
          user &&
          JSON.stringify(props.settings) !== JSON.stringify(user.settings)
        ) {
          emit("update:settings", user.settings);
          changeColorScheme(user.settings[1], false);
          changeMenuTheme(user.settings[4], false);
        }
      },
      { immediate: true }
    );

    return {
      loading,
      IamLoading,
      setSetting,
      changeColorScheme,
    };
  },
  methods: {
    onConfigButtonClick(event: any) {
      this.$emit("config-button-click", event);
      event.preventDefault();
    },
    onConfigClick(event: any) {
      this.$emit("config-click", event);
    },
  },
});
