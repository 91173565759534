import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "p-d-inline-flex p-ai-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleScreen = _resolveComponent("ToggleScreen")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_ToggleScreen, {
      onToggle: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fScreen = true))
    }),
    (!_ctx.isFirefox)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_Button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.zoom(-_ctx.step))),
            icon: "pi pi-minus",
            class: "p-ml-2 p-mr-1 p-button-text p-button-success"
          }),
          (_ctx.printZoom !== '100')
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.zoom(100, false))),
                style: {"cursor":"pointer"}
              }, _toDisplayString(_ctx.printZoom) + "%", 1))
            : _createCommentVNode("", true),
          _createVNode(_component_Button, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.zoom(_ctx.step))),
            icon: "pi pi-plus",
            class: "p-ml-1 p-button-text p-button-success"
          })
        ], 64))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}