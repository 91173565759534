import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6c2a738e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-theme"]
const _hoisted_2 = { class: "layout-content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PosTopBar = _resolveComponent("PosTopBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PosFooter = _resolveComponent("PosFooter")!
  const _component_PosConfig = _resolveComponent("PosConfig")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.containerClass),
    "data-theme": _ctx.colorSchemes[_ctx.settings[1]],
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDocumentClick()))
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PosTopBar, {
        topbarNotificationMenuActive: _ctx.topbarNotificationMenuActive,
        topbarUserMenuActive: _ctx.topbarUserMenuActive,
        "layout-mode": _ctx.menuTypes[_ctx.settings[0]],
        menuActive: _ctx.menuActive,
        menu: _ctx.menu,
        staticMenuMobileActive: _ctx.staticMenuMobileActive,
        onMenuClick: _ctx.onMenuClick,
        onMenuitemClick: _ctx.onMenuItemClick,
        onRootMenuitemClick: _ctx.onRootMenuItemClick,
        onMenuButtonClick: _ctx.onMenuButtonClick,
        onTopbarNotification: _ctx.onTopbarNotificationMenuButtonClick,
        onTopbarUserMenu: _ctx.onTopbarUserMenuButtonClick,
        activity: _ctx.activity
      }, null, 8, ["topbarNotificationMenuActive", "topbarUserMenuActive", "layout-mode", "menuActive", "menu", "staticMenuMobileActive", "onMenuClick", "onMenuitemClick", "onRootMenuitemClick", "onMenuButtonClick", "onTopbarNotification", "onTopbarUserMenu", "activity"]),
      _createElementVNode("div", {
        style: _normalizeStyle(`padding-left: ${_ctx.settings[0] !== 2 ? 1 : 2}rem`),
        class: "layout-content"
      }, [
        _createVNode(_component_router_view)
      ], 4),
      _createVNode(_component_PosFooter)
    ]),
    _createVNode(_component_PosConfig, {
      configActive: _ctx.configActive,
      "onUpdate:configActive": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.configActive) = $event)),
      "menu-types": _ctx.menuTypes,
      settings: _ctx.settings,
      "onUpdate:settings": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settings) = $event)),
      "menu-themes": _ctx.menuThemes,
      "color-schemes": _ctx.colorSchemes,
      onConfigClick: _ctx.onConfigClick,
      onConfigButtonClick: _ctx.onConfigButtonClick
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["configActive", "menu-types", "settings", "menu-themes", "color-schemes", "onConfigClick", "onConfigButtonClick"])
  ], 10, _hoisted_1))
}