
import AppMenu from "./WorkspaceMenu.vue";
import { computed, defineComponent, watch } from "vue";
import { useWHoAmI } from "@/graphql/user/whoAmi";
import { logout } from "@/graphql/session";
import { useInvitations } from "@/graphql/responsible/invitations";
import { getImage, activityLogo, CONSTANTS } from "@/graphql/utils/utils";
import { useGetSoftRemovedResponsible } from "@/graphql/responsible/get-soft-remove-responsible";
import { useDeleteDefinitivelyResponsible } from "@/graphql/responsible/delete.definitively.responsible";
import { stockExpNotifier } from "@/data/global";
import ZoomScreen from "@/components/common/ZoomScreen.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "WorkspaceTopbar",
  components: {
    ZoomScreen,
    AppMenu,
  },
  emits: [
    "menu-click",
    "menuitem-click",
    "root-menuitem-click",
    "menu-button-click",
    "topbar-notification",
    "topbar-user-menu",
  ],
  props: {
    topbarNotificationMenuActive: Boolean,
    topbarUserMenuActive: Boolean,
    layoutMode: String,
    menu: Array,
    menuActive: Boolean,
    staticMenuMobileActive: Boolean,
    activity: String,
  },
  setup(props) {
    const { invitations } = useInvitations();
    const { responsible } = useGetSoftRemovedResponsible();
    const { Iam } = useWHoAmI();
    const { removeResponsible } = useDeleteDefinitivelyResponsible();
    const { push } = useRouter();
    const goToExpiration = () => {
      sessionStorage.setItem(CONSTANTS.RAPPORT_INDEX, "7");
      void push("/workspace/rapport");
    };
    watch(
      () => props.topbarNotificationMenuActive,
      (active) => {
        if (!active) removeResponsible();
      }
    );
    return {
      Iam,
      logout,
      getImage,
      activityLogo,
      stockExpNotifier,
      count: computed(() => {
        const invitationsCount = invitations.value.meta.totalItems;
        const exp = Object.values(stockExpNotifier).reduce(
          (acc, cur) => acc + (cur > 0 ? 1 : 0),
          0
        );
        let total = responsible.value.length + invitationsCount;
        total += exp ? 1 : 0;
        return {
          invitations: invitationsCount,
          total,
          exp,
        };
      }),
      responsible,
      goToExpiration,
    };
  },
  methods: {
    onMenuClick(event: any) {
      this.$emit("menu-click", event);
    },
    onMenuItemClick(event: any) {
      this.$emit("menuitem-click", event);
    },
    onRootMenuItemClick(event: any) {
      this.$emit("root-menuitem-click", event);
    },
    onMenuButtonClick(event: any) {
      this.$emit("menu-button-click", event);
    },
    onTopbarNotificationMenuButtonClick(event: any) {
      this.$emit("topbar-notification", event);
    },
    onTopbarUserMenuButtonClick(event: any) {
      this.$emit("topbar-user-menu", event);
    },
    isMobile() {
      return window.innerWidth <= 1091;
    },
  },
});
