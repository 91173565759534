
import { computed, defineComponent, ref } from "vue";
import { api as fullscreen } from "vue-fullscreen";
import { getPlatform } from "@/data/platform";
import { AppSource } from "@/data/global";

export default defineComponent({
  name: "ToggleScreen",
  emits: ["toggle"],
  props: {
    pos: {
      type: Boolean,
      default: true,
    },
  },
  setup(_, { emit }) {
    const isFullscreen = ref(false);
    document.body.addEventListener("keydown", function (event) {
      if (event.key === "F11") {
        emit("toggle");
        isFullscreen.value = !isFullscreen.value;
      } else if (event.key == "Escape" && isFullscreen.value) {
        emit("toggle");
        isFullscreen.value = false;
      }
    });
    function toggle() {
      emit("toggle");
      fullscreen.toggle(document.querySelector("html"), {
        callback: (isFullscreen: boolean) => {
          this.isFullscreen = isFullscreen;
        },
      });
    }
    return {
      isWeb: getPlatform() == AppSource.web.name,
      isFullscreen,
      toggle,
      ion: computed(
        () => `pi pi-window-${isFullscreen.value ? "minimize" : "maximize"}`
      ),
    };
  },
});
