import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { MutationDeleteDefinitivelyResponsibleArgs } from "@/graphql/types";
import { activeActivity } from "@/plugins/i18n";

type DeleteDefinitivelyResponsibleData = {
  deleteDefinitivelyResponsible: string;
};

const DELETE_DEFINITIVELY = gql`
  mutation DeleteDefinitivelyResponsible($activityId: Int!) {
    deleteDefinitivelyResponsible(activityId: $activityId)
  }
`;

export const useDeleteDefinitivelyResponsible = () => {
  const { mutate } = useMutation<
    DeleteDefinitivelyResponsibleData,
    MutationDeleteDefinitivelyResponsibleArgs
  >(DELETE_DEFINITIVELY, {
    update: (cache, { data }) => {
      if (data.deleteDefinitivelyResponsible) {
        cache.modify({
          fields: {
            getSoftRemovedResponsible() {
              return [];
            },
          },
        });
      }
    },
  });

  function removeResponsible() {
    void mutate({ activityId: activeActivity.value.id });
  }

  return { removeResponsible };
};
