
import { defineComponent, ref, onMounted } from "vue";
import ToggleScreen from "@/components/common/ToggleScreen.vue";
import { CONSTANTS } from "@/graphql/utils/utils";

export default defineComponent({
  name: "ZoomScreen",
  components: { ToggleScreen },
  props: {
    pos: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const isFirefox = /firefox|fxios/i.test(window.navigator.userAgent);
    function getLocalZoom() {
      return Number((localStorage.getItem(CONSTANTS.zoom) || "0").trim());
    }
    function getZoomLevel() {
      return Number((localStorage.getItem(CONSTANTS.zoomLevel) || "0").trim());
    }
    const zoomAmount = ref(getLocalZoom() || 100);
    const printZoom = ref((getZoomLevel() || zoomAmount.value).toFixed(0));
    const fScreen = ref(false);
    const min = 50;
    const max = 200;
    /*let scaleAmount = 1;
    const min = 0.1;
    const max = 10;*/

    function zoom(step: number, isStep = true) {
      if (isFirefox) return;
      const container = document.getElementById("container");
      const body = document.querySelector("body");
      if (!isStep) zoomAmount.value = step;
      else {
        zoomAmount.value += step;
        if (zoomAmount.value < min) zoomAmount.value = min;
        else if (zoomAmount.value > max) zoomAmount.value = max;
      }
      (body.style as any).zoom = `${zoomAmount.value}%`;
      localStorage.setItem(CONSTANTS.zoom, zoomAmount.value.toFixed(0));
      printZoom.value = zoomAmount.value.toFixed(0);
      localStorage.removeItem(CONSTANTS.zoomLevel);
      if (!container) return;
      container.style.position = "relative";
      if (step > 0) container.style.position = "fixed";
      container.style.height = `${body.clientHeight}px`;
    }

    //window.addEventListener("keydown", getSizes, false);
    const isTouchDevice = () => {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        (navigator as any)?.msMaxTouchPoints > 0
      );
    };

    function addEvent() {
      window.addEventListener("resize", () => {
        if (fScreen.value) {
          fScreen.value = false;
          zoom(0.00001);
          return;
        }
        if (/Mob/i.test(navigator.userAgent) || isFirefox || isTouchDevice())
          return;
        const z = window.devicePixelRatio * 100;
        if (z != 100) {
          zoom(100, false);
          printZoom.value = z.toFixed(0);
          localStorage.setItem(CONSTANTS.zoomLevel, printZoom.value);
        } else zoom(0.00001);
      });
    }

    onMounted(() => {
      const z = getLocalZoom();
      if (z && z != 100) zoom(z, false);
      setTimeout(() => addEvent(), 50);
    });
    /*function zoom(step: number) {
      const container = document.getElementById("container");
      const body = document.querySelector("body");
      scaleAmount += step;
      if (scaleAmount < min) scaleAmount = min;
      else if (scaleAmount > max) scaleAmount = max;
      body.style.transform = `scale(${scaleAmount})`;
      body.style.transformOrigin = `top left`;
      container.style.height = `${body.clientHeight}px`;
      container.style.width = `${body.clientWidth}px`;
    }*/
    return {
      zoom,
      printZoom,
      isFirefox,
      nav: window.navigator.userAgent,
      step: 5,
      fScreen,
    };
  },
});
