import { AppSource } from "@/data/global";
import { CONSTANTS } from "@/graphql/utils/utils";

export const getPlatform = () => {
  const accountBase = localStorage.getItem(CONSTANTS.appSource);
  if (accountBase.includes(AppSource.desktop.account)) {
    return AppSource.desktop.name;
  } else if (accountBase.includes(AppSource.android.account)) {
    return AppSource.android.name;
  } else if (accountBase.includes(AppSource.ios.account)) {
    return AppSource.ios.name;
  } else {
    return AppSource.web.name;
  }
};
