<template>
  <div class="layout-footer">
    <div class="footer-logo-container">
      <img style="width: 48px; height: 48px" src="/locaa.png" alt="locaa" />
      <span class="app-name">LOCAA</span>
    </div>
    <span class="copyright">
      &#169; locaa.net - {{ new Date().getFullYear() }}
    </span>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
