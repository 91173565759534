import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.isWeb)
    ? (_openBlock(), _createBlock(_component_Button, {
        key: 0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle())),
        icon: _ctx.ion,
        class: "p-button-outlined"
      }, null, 8, ["icon"]))
    : _createCommentVNode("", true)
}